import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { HeaderService } from '../../../../../service/header.service'
import { AuthenticationService } from '../../../../../utils/authentication.service'
import { CommonHttpService } from 'src/app/utils/common-http.service';
import constant from 'src/app/app.constant';
import { LocalStorageService } from '../../../../../utils/local-storage.service'
import { Router } from '@angular/router';
import { AppComponent } from '../../../../../app.component';
import commonLogics from 'src/app/utils/common-logic.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

  notifications: any[];
  moduleList: any;
  moduleId: any;
  public constant = constant;
  companyName: any;
  logoUrl: string = "";
  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    public headerService: HeaderService,
    private auth: AuthService,
    public authService: AuthenticationService,
    private http: CommonHttpService,
    public localData: LocalStorageService,
    private router: Router,
    private appData: AppComponent
  ) {
    this.notifications = [
      {
        icon: 'i-Speach-Bubble-6',
        title: 'New message',
        badge: '3',
        text: 'James: Hey! are you busy?',
        time: new Date(),
        status: 'primary',
        link: '/chat'
      },
      {
        icon: 'i-Receipt-3',
        title: 'New order received',
        badge: '$4036',
        text: '1 Headphone, 3 iPhone x',
        time: new Date('11/11/2018'),
        status: 'success',
        link: '/tables/full'
      },
      {
        icon: 'i-Empty-Box',
        title: 'Product out of stock',
        text: 'Headphone E67, R98, XL90, Q77',
        time: new Date('11/10/2018'),
        status: 'danger',
        link: '/tables/list'
      },
      {
        icon: 'i-Data-Power',
        title: 'Server up!',
        text: 'Server rebooted successfully',
        time: new Date('11/08/2018'),
        status: 'success',
        link: '/dashboard/v2'
      },
      {
        icon: 'i-Data-Block',
        title: 'Server down!',
        badge: 'Resolved',
        text: 'Region 1: Server crashed!',
        time: new Date('11/06/2018'),
        status: 'danger',
        link: '/dashboard/v3'
      }
    ];
  }
  emailTo = 'support@engaje.com.au';

  ngOnInit() {
    let newDate: any = moment().format('x');

    setTimeout(() => {
      this.getUserModule();
      this.moduleId = this.localData.getItem(constant.localStorage.module_id);
      this.companyName = this.localData.getItem(constant.localStorage.company_name);
      this.logoUrl = constant.ImageUrl.BaseUrl + constant.ImageUrl.Engaje.ShowImage + this.localData.getItem(constant.localStorage.logo_url) + '&=' + newDate;
    }, 500)

    this.headerService.userDetails.subscribe((user: any) => {
      let nowDate: any = moment().format('x');
      setTimeout(() => {
        if (user?.logoUrl) {
          this.companyName = this.localData.getItem(constant?.localStorage?.company_name);
          this.logoUrl = constant.ImageUrl.BaseUrl + constant.ImageUrl.Engaje.ShowImage + this.localData.getItem(constant?.localStorage?.logo_url) + '&=' + nowDate;
        }
      }, 500);
    });

  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    // item has child items
    if (!state.sidenavOpen && !state.childnavOpen
      && this.navService.selectedItem?.type === 'dropDown') {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
    // item has no child items
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  signout() {
    this.authService.logout();
  }
  emailOpen() {
    // var emailCC = 'vignesh@adloggs.com';
    // location.href = "mailto:" + this.emailTo + '?cc=' + emailCC;
    location.href = "mailto:" + this.emailTo;
  }
  getUserModule() {
    var header: any = {
      'x-access-token': this.localData.getItem(constant.localStorage.token),
      'Content-Type': "application/json",
      'utc-offset': commonLogics.getUtcOffset(),
    }
    this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.module.userModules, { headers: header }).subscribe((response: any) => {
      if (response.data) {
        this.moduleList = response.data;
        this.moduleList.find((item: any) => {
          if (item.Module.logo) {
            item.logo = constant.ImageUrl.Engaje.LoginShowImage + item.Module.logo;
          }
        })
      }
    })
  }
  getModuleId(Id: any) {
    this.localData.addItem(constant.localStorage.module_id, Id);
    this.moduleId = this.localData.getItem(constant.localStorage.module_id);
    this.authService.sideBarMenu();
    this.appData.getTranslate();
    switch (this.localData.getItem(constant.localStorage.module_id)) {
      case constant.moduleId.ekart:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['orders/orderlist'])
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.foodKart:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['orders/orderlist'])
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.cConnect:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['/customers/customerlist']);
        } else {
          this.router.navigate(['/customers/customerlist']);
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.skipques:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['/customers/customerlist']);
        } else {
          this.router.navigate(['/customers/customerlist']);
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.service:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['orders/orderlist'])
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.tableOrdering:
        if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['table/tablecustomerlist'])
        }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;
      case constant.moduleId.job:
        // if (this.localData.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
        //   this.router.navigate(['job/joblist']);
        // } else {
        this.router.navigate(['job/interest'])
        // }
        this.authService.getUserDetails('0');
        setTimeout(() => {
          window.location.reload()
        }, 200)
        break;

    }
  }

}
