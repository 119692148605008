import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      switch (err.status) {
        case 401:
          this.authenticationService.logout();
          break;
        case 400:
          this.authenticationService.logout();
          break;
        case 500:
          this.authenticationService.logout();
          // console.log('create internal error page')
          break;
      }
      const error = err.error?.message || err.statusText;
      return throwError(error);

    }))
  }
}