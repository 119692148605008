<div class="main-header">
    <div class="logo">
        <img src="./assets/images/logo.png" alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="d-flex align-items-center">
        <!-- Mega menu -->
        <!-- <div ngbDropdown class="mega-menu d-none d-md-block">
            <button ngbDropdownToggle href="#" class="btn text-muted dropdown-toggle mr-3">Mega Menu</button>
            <div ngbDropdownMenu perfectScrollbar>
                <div class="row m-0">
                    <div class="col-md-4 p-4 bg-img">
                        <h2 class="title">Mega Menu <br> Sidebar</h2>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores natus
                            laboriosam fugit, consequatur.
                        </p>
                        <p class="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                            Exercitationem odio amet eos dolore suscipit placeat.</p>
                        <button class="btn btn-lg btn-rounded btn-outline-warning">Learn More</button>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Features</p>
                        <div class="menu-icon-grid w-auto p-0">
                            <a href="#"><i class="i-Shop-4"></i> Home</a>
                            <a href="#"><i class="i-Library"></i> UI Kits</a>
                            <a href="#"><i class="i-Drop"></i> Apps</a>
                            <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                            <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                            <a href="#"><i class="i-Ambulance"></i> Support</a>
                        </div>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Components</p>
                        <ul class="links">
                            <li><a href="accordion.html">Accordion</a></li>
                            <li><a href="alerts.html">Alerts</a></li>
                            <li><a href="buttons.html">Buttons</a></li>
                            <li><a href="badges.html">Badges</a></li>
                            <li><a href="carousel.html">Carousels</a></li>
                            <li><a href="lists.html">Lists</a></li>
                            <li><a href="popover.html">Popover</a></li>
                            <li><a href="tables.html">Tables</a></li>
                            <li><a href="datatables.html">Datatables</a></li>
                            <li><a href="modals.html">Modals</a></li>
                            <li><a href="nouislider.html">Sliders</a></li>
                            <li><a href="tabs.html">Tabs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Search bar -->
        <!-- <div class="search-bar">
            <input type="text" placeholder="Search" (focus)="searchService.searchOpen = true">
            <i class="search-icon text-muted i-Magnifi-Glass1"></i>
        </div> -->
    </div>

    <div style="margin: auto;"></div>

    <!-- margquee alert -->
    <div class="en-marquee-container" style="margin: auto;">
        <div class="inactive_status" *ngIf="localData.getItem(constant.localStorage.login_status) == 'InActive'">
            <div class="marquee">
                <div class="marquee-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#cb0000">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                            d="M16 2l5 5v14.008a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16zm-5 13v2h2v-2h-2zm0-8v6h2V7h-2z" />
                    </svg>
                </div>
                <marquee direction="left" onmouseover="this.stop();" onmouseout="this.start();">
                    <div class="marquee-content">
                        Looks like your account is inactive. Please contact your Community Administrator for further
                        information. You can also get information from <span class="marquee-tag"><a
                                href="mailto:support@engaje.com.au" target="_blank" (click)="emailOpen()"><i
                                    class="ri-mail-open-line"></i> support@engaje.com.au </a></span>
                    </div>
                </marquee>
            </div>
        </div>
    </div>
    <!-- end marquee alert -->

    <div class="header-part-right">
        <!-- <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i> -->
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Posterous text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid en-menu-icon-grid">
                    <!-- <a href="#" (click)="getBusiness()"><i class="i-Shop-4"></i> Home</a> -->
                    <!-- <div > -->
                    <a [style.background-color]="(module.module_id == moduleId) ? '#FCA311' : '#ffffff' "
                        *ngFor="let module of moduleList;let i=index" (click)="getModuleId(module.Module.module_id)">
                        <div class="avatar mb-2">
                            <img src={{module.logo}} (error)="module.logo = './assets/images/abstrac.jpg';" alt="">
                        </div>
                        {{module.Module.name}}
                    </a>
                    <!-- </div> -->
                    <!-- <a href="#"><i class="i-Library"></i> UI Kits</a>
                    <a href="#"><i class="i-Drop"></i> Apps</a>
                    <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                    <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                    <a href="#"><i class="i-Ambulance"></i> Support</a> -->
                </div>
            </div>
        </div>

        <div ngbDropdown [placement]="'bottom-right'">
            <!-- <div class="badge-top-container">
                <span class="badge badge-primary">{{notifications.length}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
            </div> -->
            <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                    <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} mr-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <span>{{item.title}}</span>
                            <span *ngIf="item.badge"
                                class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ml-auto">{{item.time | relativeTime}}</span>
                        </p>
                        <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <img src="{{logoUrl}}" (error)="logoUrl = './assets/images/faces/1.png';" id="userDropdown"
                ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header" *ngIf="companyName">
                    <i class="ri-user-fill"></i><span class="ml-1">{{companyName?companyName:''}}</span>
                </div>
                <button class="dropdown-item" routerLink="/actions/settings"
                    *ngIf="localData.getItem(constant.localStorage.role_id) != 4">Account settings</button>
                <!-- <button class="dropdown-item">Billing history</button> -->
                <button class="dropdown-item" (click)="signout()">Sign out</button>
            </div>
        </div>

    </div>

</div>