import * as moment from 'moment';
import { LocalStorageService } from './../utils/local-storage.service';
import constant from './../app.constant';

export class CommonLogicService {
    public localData: any = new LocalStorageService();

    public getUtcOffset() {
        return new Date().getTimezoneOffset() * -1;
    }
    public getCurrentDate() {
        return moment(new Date()).format(constant.DateFormatServerSideWithDateTime);
    }
    // public convertLocalToUtcTime(date: any) {
    //     var utcTime = moment.utc(date, constant.dateTimeFormat).utc().format(constant.DateFormatServerSideWithDateTime)
    //     return utcTime
    // }
    public givenDateTimeAndOffsetToChangeUtcTime(dateTime: any) {
        var reqUtcOffset = -1 * this.getUtcOffset();
        var utcTime = moment.utc(dateTime, constant.dateTimeFormat).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        return utcTime;
    }
    // public currentUtcToReqTimeConversion(date: any) {
    //     var utcOffset = this.getUtcOffset();
    //     return moment.utc(this.utcDateTime(), constant.DateFormatServerSideWithDateTime).utcOffset(Number(utcOffset)).format(constant.DateFormatServerSideWithDateTime);
    // }
    // public utcDateTime() {
    //     return new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    // }
    public isObject(value: any) {
        return typeof value === 'object';
    }
    public dateFormaterStringToJson(date: any) {
        var reqJson: any = {}
        reqJson.day = parseInt(moment(date, 'YYYY-MM-DD').format('DD'));
        reqJson.month = parseInt(moment(date, 'YYYY-MM-DD').format('MM'));
        reqJson.year = parseInt(moment(date, 'YYYY-MM-DD').format('YYYY'));
        return reqJson;
    }
    public convertUTCDateToLocalDate(date: any) {
        var local = moment.utc(date, constant.DateFormatServerSideWithDateTime).local().format(constant.DateFormatServerSideWithDateTime);
        return local;
    }
    public convertUTCTimeToLocalTime(time: any) {
        var dateTime = moment().format('YYYY-MM-DD') + time;
        var localString = moment.utc(dateTime, constant.DateFormatServerSideWithDateTime).local().format(constant.DateFormatServerSideWithDateTime);
        const date = moment(localString, 'YYYY-MM-DD HH:mm');
        const local = date.format('HH:mm');
        return local;
    }
    public convertToLocalToUtcTime(time: any) {
        var dateTime = moment().format('YYYY-MM-DD') + time;
        var reqUtcOffset = -1 * this.getUtcOffset();
        var localString = moment.utc(dateTime, constant.DateFormatServerSideWithDateTime).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        const date = moment(localString, 'YYYY-MM-DD HH:mm');
        const utcTime = date.format('HH:mm');
        return utcTime;
    }
    public convertLocalDate(date: any) {
        var local = moment(date, constant.DateFormatServerSideWithDateTime).format(constant.DateFormatServerSideDate);
        return local;
    }
    public convertToServeDate(date: any) {
        var serveDate = moment(date, constant.DateFormatHtml).format(constant.DateFormatServerSideDate);
        return serveDate;
    }
    public timeFormaterJsonToString(reqJson: any, format: any) {
        let time: any = `${reqJson.hour}-${reqJson.minute}`
        return moment(time, 'HH:mm').format(format);
    }
    public timeFormaterStringToJson(time: any) {
        var reqJson: any = {}
        reqJson.hour = parseInt(moment(time, 'HH:mm:ss').format('HH'));
        reqJson.minute = parseInt(moment(time, 'HH:mm:ss').format('mm'));
        // reqJson.seconds = parseInt(moment(time, 'HH:mm:ss').format('ss'));
        return reqJson;
    }
    public orderStatus(data: any) {
        switch (this.localData.getItem(constant.localStorage.module_id)) {
            case constant.moduleId.ekart:
                return data;
            case constant.moduleId.foodKart:
            case constant.moduleId.tableOrdering:
                if (data == constant.orderStatusName[3]) {
                    return 'Cooking';
                } else if (data == constant.orderStatusName[2]) {
                    return "Accept";
                } else {
                    return data;
                }
            case constant.moduleId.service:
                if (data == constant.orderStatusName[2]) {
                    return 'Accept';
                } else if (data == constant.orderStatusName[4]) {
                    return "Cancelled";
                } else {
                    return data;
                }
        }
    }
    public deliveryType(data: any) {
        return constant.DeliveryType[this.localData.getItem(constant?.localStorage?.module_id)][data]
    }
    public add(val: any, val2: any) {
        return Number(Number(val) + Number(val2)).toFixed(2)
    }
    public moduleWiseOrderStatus(data: any) {
        switch (this.localData.getItem(constant.localStorage.module_id)) {
            case constant.moduleId.ekart:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Received', order_status_id: 2 }, { order_status_name: 'Processing', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.foodKart:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.service:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Cancelled', order_status_id: 4 },];
                break;
            case constant.moduleId.tableOrdering:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
        }
        return data;
    }
}

let commonLogics = new CommonLogicService();
export default commonLogics;
