import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from 'ng-connection-service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/utils/local-storage.service';
import constant from './app.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';
  status = 'ONLINE';
  isConnected = true;
  constructor(private toaster: ToastrService, private connectionService: ConnectionService, public translate: TranslateService, private localData: LocalStorageService) {

    this.getTranslate();
    // translate.setDefaultLang('ekart');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/ekart|foodKart|service/) ? browserLang : 'ekart');

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        this.toaster.success('Internet connected');
      } else {
        this.status = "OFFLINE";
        this.toaster.error('Looks like you have internet connectivity issues you can wait for better connection or you can close and re-open this app');
      }
    })

  }
  getTranslate() {
    this.translate.addLangs(['ekart', 'foodKart', 'service', 'tableordering', 'job']);
    switch (this.localData.getItem(constant.localStorage.module_id)) {
      case constant.moduleId.ekart:
        this.translate.setDefaultLang('ekart');
        this.translate.use('ekart');
        document.documentElement.style.setProperty('--primary-color', '#03A9F5');
        break;
      case constant.moduleId.foodKart:
        this.translate.setDefaultLang('foodKart');
        this.translate.use('foodKart');
        document.documentElement.style.setProperty('--primary-color', '#f9a602');
        break;
      case constant.moduleId.cConnect:
        this.translate.setDefaultLang('service');
        this.translate.use('service');
        document.documentElement.style.setProperty('--primary-color', '#ad5178');
        break;
      case constant.moduleId.skipques:
        this.translate.setDefaultLang('ekart');
        this.translate.use('ekart');
        document.documentElement.style.setProperty('--primary-color', '#9C27B0');
        break;
      case constant.moduleId.service:
        this.translate.setDefaultLang('service');
        this.translate.use('service');
        document.documentElement.style.setProperty('--primary-color', '#d22346');
        break;
      case constant.moduleId.tableOrdering:
        this.translate.setDefaultLang('tableordering');
        this.translate.use('tableordering');
        document.documentElement.style.setProperty('--primary-color', '#4CAF50');
        break;
      case constant.moduleId.job:
        this.translate.setDefaultLang('job');
        this.translate.use('job');
        document.documentElement.style.setProperty('--primary-color', '#17c3b2');
        break;

      default:
        document.documentElement.style.setProperty('--primary-color', '#14223D');
        break;
    }
  }
}
