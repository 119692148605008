import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonHttpService } from './common-http.service';
import { LocalStorageService } from './local-storage.service';
import constant from './../app.constant';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../shared/services/navigation.service'
import commonLogics from './common-logic.service';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    public showLoader: boolean = false;
    public userDetails: any = {};
    notifymessage: any;

    constructor(
        private http: CommonHttpService,
        private router: Router,
        private localStorage: LocalStorageService,
        public toster: ToastrService,
        public navService: NavigationService,
        // public headerService: HeaderService
    ) { }

    tokenSetAndCheckInLocalStorage(token: any) {
        if (token) {
            this.localStorage.addItem(constant.localStorage.token, token);
            this.getUserDetails('0');
        } else if (!this.localStorage.getItem(constant.localStorage.token)) {
            this.toster.error('Session Expired');
            // location.href = constant.Client.Auth.BaseUrl;
        }
    }
    loginToken() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            if (this.localStorage.getItem(constant.localStorage.initial_payment)) {
                if (this.localStorage.getItem(constant.localStorage.module_id)) {
                    // this.router.navigate(['/dashboard']);
                    this.getUserDetails('1');
                } else {
                    this.router.navigate(['/sessions/settings']);
                }
            } else {
                this.router.navigate(['/sessions/settings']);
            }
        }
        else {
            // this.router.navigate(['/appcenter/createpwd'], { queryParams: { code: userData.reset_pw_code } })
        }
    }

    getUserDetails(id: any) {
        var header: any = {
            'x-access-token': this.localStorage.getItem(constant.localStorage.token),
            'Content-Type': "application/json",
            'utc-offset': commonLogics.getUtcOffset(),
        }
        this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.User.GetUser, { headers: header }).subscribe((response: any) => {
            if (response.status) {
                this.userDetails = response.data;
                this.localStorage.addItem(constant.localStorage.user_name, this.userDetails?.full_name);
                this.localStorage.addItem(constant.localStorage.first_name, this.userDetails?.name);
                this.localStorage.addItem(constant.localStorage.role_id, this.userDetails?.role_id);
                this.localStorage.addItem(constant.localStorage.phone, this.userDetails?.phone);
                this.localStorage.addItem(constant.localStorage.email, this.userDetails?.email);

                this.localStorage.addItem(constant.localStorage.initial_payment, this.userDetails?.initial_payment);
                this.localStorage.addItem(constant.localStorage.use_engaje_payment_account, this.userDetails?.use_engaje_payment_account);
                this.localStorage.addItem(constant.localStorage.company_details_id, this.userDetails?.CompanyDetail?.company_details_id);
                this.localStorage.addItem(constant.localStorage.login_status, this.userDetails?.status);
                this.localStorage.addItem(constant.localStorage.company_name, this.userDetails?.company_name);
                this.localStorage.addItem(constant.localStorage.logo_url, this.userDetails?.logo_url);
                if (id == '1') {
                    this.localStorage.addItem(constant.localStorage.module_id, this.userDetails?.module_id);
                    switch (this.userDetails?.module_id) {
                        case constant.moduleId.ekart:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['dashboard']);
                            } else {
                                this.router.navigate(['orders/orderlist'])
                            }
                            break;
                        case constant.moduleId.foodKart:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['dashboard']);
                            } else {
                                this.router.navigate(['orders/orderlist'])
                            }
                            break;
                        case constant.moduleId.cConnect:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['/customers/customerlist']);
                            } else {
                                this.router.navigate(['/customers/customerlist']);
                            }
                            break;
                        case constant.moduleId.skipques:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['/customers/customerlist']);
                            } else {
                                this.router.navigate(['/customers/customerlist']);
                            }
                            break;
                        case constant.moduleId.service:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['dashboard']);
                            } else {
                                this.router.navigate(['orders/orderlist'])
                            }
                            break;
                        case constant.moduleId.tableOrdering:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['dashboard']);
                            } else {
                                this.router.navigate(['table/tablecustomerlist'])
                            }
                            break;
                        case constant.moduleId.job:
                            if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                                this.router.navigate(['job/interest']);
                            } else {
                                this.router.navigate(['job/joblist'])
                            }
                            break;
                    }
                } else if (id != 0) {
                    this.localStorage.addItem(constant.localStorage.module_id, this.userDetails?.module_id);
                    this.router.navigate(['orders/orderview'], { queryParams: { id: id } })
                }
                if (this.userDetails.role_id == 4) {
                    this.localStorage.addItem(constant.localStorage.user_id, this.userDetails?.company_user_id);
                } else {
                    this.localStorage.addItem(constant.localStorage.user_id, this.userDetails?.user_id);
                }
            }
            this.sideBarMenu();
        })
    }


    sideBarMenu() {
        var reqJson: any = {};
        var defaultMenu = [];

        switch (this.localStorage.getItem(constant.localStorage.module_id)) {
            case constant.moduleId.ekart:
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Dashboard',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Bar-Chart',
                        state: '/dashboard'
                    },
                        defaultMenu.push(reqJson);
                }
                reqJson = {
                    name: 'Orders',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Receipt-4',
                    state: '/orders/orderlist'
                },
                    defaultMenu.push(reqJson);
                reqJson = {
                    name: 'Products',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Calculator-2',
                    state: '/products/productlist',
                    // sub: [
                    //     { icon: 'i-Bell', name: 'Product list', state: '/products/productlist', type: 'link' },
                    //     { icon: 'i-Split-Horizontal-2-Window', name: 'Category', state: '/products/category', type: 'link' },
                    //     { icon: 'i-Medal-2', name: 'Sub category', state: '/products/subcategory', type: 'link' },
                    //     { icon: 'i-ID-Card', name: 'Manufacture', state: '/products/manufacturer', type: 'link' },
                    //     { icon: 'i-Full-Cart', name: 'Addons', state: '/products/productspec', type: 'link' },
                    // ]
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Customers',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Business-ManWoman',
                        state: '/customers/customerlist'
                    },
                        defaultMenu.push(reqJson);
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-qr-code-line', name: 'Link Qrcode', state: '/actions/qrcode', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-bank-card-2-line', name: 'Payment setup', state: '/payacc/success', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                            { icon: 'ri-sparkling-line', name: 'Promotion', state: '/actions/promotion', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                break;

            case constant.moduleId.foodKart:
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Dashboard',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Bar-Chart',
                        state: '/dashboard'
                    },
                        defaultMenu.push(reqJson);
                }
                reqJson = {
                    name: 'Orders',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Receipt-4',
                    state: '/orders/orderlist'
                },
                    defaultMenu.push(reqJson);
                reqJson = {
                    name: 'Products',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Calculator-2',
                    state: '/products/productlist',
                    // sub: [
                    //     { icon: 'i-Bell', name: 'Product list', state: '/products/productlist', type: 'link' },
                    //     { icon: 'i-Split-Horizontal-2-Window', name: 'Category', state: '/products/category', type: 'link' },
                    //     { icon: 'i-Medal-2', name: 'Sub category', state: '/products/subcategory', type: 'link' },
                    //     { icon: 'i-ID-Card', name: 'Manufacture', state: '/products/manufacturer', type: 'link' },
                    //     { icon: 'i-Full-Cart', name: 'Addons', state: '/products/productspec', type: 'link' },
                    // ]
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Customers',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Business-ManWoman',
                        state: '/customers/customerlist'
                    },
                        defaultMenu.push(reqJson);
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-qr-code-line', name: 'Link Qrcode', state: '/actions/qrcode', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-bank-card-2-line', name: 'Payment setup', state: '/payacc/success', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                            { icon: 'ri-sparkling-line', name: 'Promotion', state: '/actions/promotion', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                break;

            case constant.moduleId.cConnect:
                reqJson = {
                    name: 'Customers',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Business-ManWoman',
                    state: '/customers/customerlist'
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-qr-code-line', name: 'Link Qrcode', state: '/actions/qrcode', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                break;
            case constant.moduleId.skipques:
                reqJson = {
                    name: 'Customers',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Business-ManWoman',
                    state: '/customers/customerlist'
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Gear',
                        state: '/actions/settings'
                    }
                    defaultMenu.push(reqJson);
                }
                break;
            case constant.moduleId.service:
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Dashboard',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Bar-Chart',
                        state: '/dashboard'
                    },
                        defaultMenu.push(reqJson);
                }
                reqJson = {
                    name: 'Bookings',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Receipt-4',
                    state: '/orders/orderlist'
                },
                    defaultMenu.push(reqJson);
                reqJson = {
                    name: 'Services',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Calculator-2',
                    state: '/products/productlist',
                    // sub: [
                    //     { icon: 'i-Bell', name: 'Service list', state: '/products/productlist', type: 'link' },
                    //     { icon: 'i-Split-Horizontal-2-Window', name: 'Category', state: '/products/category', type: 'link' },
                    //     { icon: 'i-Medal-2', name: 'Sub category', state: '/products/subcategory', type: 'link' },
                    //     { icon: 'i-ID-Card', name: 'Manufacture', state: '/products/manufacturer', type: 'link' },
                    //     { icon: 'i-Full-Cart', name: 'Addons', state: '/products/productspec', type: 'link' },
                    // ]
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Customers',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Business-ManWoman',
                        state: '/customers/customerlist'
                    },
                        defaultMenu.push(reqJson);
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-qr-code-line', name: 'Link Qrcode', state: '/actions/qrcode', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-bank-card-2-line', name: 'Payment setup', state: '/payacc/success', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                            { icon: 'ri-sparkling-line', name: 'Promotion', state: '/actions/promotion', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                break;
            case constant.moduleId.tableOrdering:
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Dashboard',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Bar-Chart',
                        state: '/dashboard'
                    },
                        defaultMenu.push(reqJson);
                }
                reqJson = {
                    name: 'Table Orders',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Receipt-4',
                    state: '/table/tablecustomerlist'
                },
                    defaultMenu.push(reqJson);
                reqJson = {
                    name: 'Products',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Calculator-2',
                    state: '/products/productlist',
                    // sub: [
                    //     { icon: 'i-Bell', name: 'Product list', state: '/products/productlist', type: 'link' },
                    //     { icon: 'i-Split-Horizontal-2-Window', name: 'Category', state: '/products/category', type: 'link' },
                    //     { icon: 'i-Medal-2', name: 'Sub category', state: '/products/subcategory', type: 'link' },
                    //     { icon: 'i-ID-Card', name: 'Manufacture', state: '/products/manufacturer', type: 'link' },
                    //     { icon: 'i-Full-Cart', name: 'Addons', state: '/products/productspec', type: 'link' },
                    // ]
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Customers',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Business-ManWoman',
                        state: '/customers/customerlist'
                    },
                        defaultMenu.push(reqJson);
                    reqJson = {
                        name: 'Tables',
                        description: '600+ premium icons',
                        type: 'link',
                        icon: 'i-Building',
                        state: '/table/tableorder'
                    }
                    defaultMenu.push(reqJson);
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-bank-card-2-line', name: 'Payment setup', state: '/payacc/success', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                            { icon: 'ri-sparkling-line', name: 'Promotion', state: '/actions/promotion', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                break;
            case constant.moduleId.job:
                // if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                // reqJson = {
                //     name: 'Dashboard',
                //     description: '600+ premium icons',
                //     type: 'link',
                //     icon: 'i-Bar-Chart',
                //     state: '/dashboard'
                // },
                //     defaultMenu.push(reqJson);
                // }
                reqJson = {
                    name: 'Job applied',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Love-User',
                    state: '/job/interest'
                }
                defaultMenu.push(reqJson);
                reqJson = {
                    name: 'Jobs',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Receipt-4',
                    state: '/job/joblist'
                },
                    defaultMenu.push(reqJson);
                if (this.localStorage.getItem(constant.localStorage.role_id) != constant.RoleId.staff) {
                    reqJson = {
                        name: 'Actions',
                        description: '600+ premium icons',
                        type: 'dropDown',
                        icon: 'i-Gear',
                        state: '/actions/settings',
                        sub: [
                            { icon: 'ri-settings-2-line', name: 'Settings', state: '/actions/settings', type: 'link' },
                            { icon: 'ri-qr-code-line', name: 'Link Qrcode', state: '/actions/qrcode', type: 'link' },
                            { icon: 'ri-community-line', name: 'Community', state: '/actions/communityjoin', type: 'link' },
                            { icon: 'ri-mail-check-line', name: 'Subscription', state: '/actions/subscription', type: 'link' },
                            { icon: 'ri-shake-hands-line', name: 'Business Types', state: '/actions/industry_types', type: 'link' },
                        ]
                    }
                    defaultMenu.push(reqJson);
                }
                reqJson = {
                    name: 'Customers',
                    description: '600+ premium icons',
                    type: 'link',
                    icon: 'i-Business-ManWoman',
                    state: '/customers/customerlist'
                },
                    defaultMenu.push(reqJson);
                break;
        }


        if (this.localStorage.getItem(constant.localStorage.role_id) == constant.RoleId.business) {
            reqJson = {
                name: 'Staff',
                description: '600+ premium icons',
                type: 'link',
                icon: 'i-Administrator',
                state: '/staff/staff'
            }
            defaultMenu.push(reqJson);
        }
        this.navService.menuItems.next(defaultMenu);
    }
    logout() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.User.LogOut).subscribe((response: any) => {
                this.toster.success('Logout Success');
                //     console.log(response)
                this.logoutWithoutBackendHit();
            }, err => {
                this.toster.success('Logout Success');
                this.logoutWithoutBackendHit();
            })
        } else {
            this.logoutWithoutBackendHit();
        }
    }

    logoutWithoutBackendHit() {
        this.localStorage.clearAllItem();
        location.href = constant.Client.Auth.BaseUrl;
    }

}
